import React from "react";
import Title from "./Title";
import Search from "./Search";
import { Container, Description } from "./styles";

function Benefits({ onSearch, isResetSearch }) {
  return (
    <Container>
      <Title />
      <Description>
        Get Answers to all your email marketing queries in one place
      </Description>
      <Search {...{ onSearch, isResetSearch }} />
    </Container>
  );
}

export default Benefits;
