import { fetchResources } from "./queries";

const getResources = async (isQaEnv) => {
  try {
    const result = await fetchResources(isQaEnv);

    if (result.message) {
      return result;
    }

    return { success: true, megaPages: result.seoData };
  } catch (error) {
    return { success: false, message: "Get resources failed" };
  }
};

export { getResources };
