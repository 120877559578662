import React, { useCallback, useMemo } from "react";
import { useMediaQuery } from "react-responsive";

import { Container, TextHighlight } from "./styles";

function Title() {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const {
    rotationWords = {
      titleWord: "Email Marketing",
      descriptionWord: "",
    },
    fade = {
      isTitleWordFade: false,
      isDescriptionWordFade: false,
    },
  } = {};
  const memoizedRenderTextHighlight = useCallback(
    (word, isFade) => renderTextHighlight(word, isFade),
    [],
  );
  const titleContent = useMemo(
    () => ({
      desktop: (
        <>
          {" "}
          Ultimate{" "}
          {memoizedRenderTextHighlight(
            rotationWords.titleWord,
            fade.isTitleWordFade,
          )}{" "}
          Guide
        </>
      ),
      mobile: (
        <>
          Your Ultimate
          <br />
          {memoizedRenderTextHighlight(
            rotationWords.titleWord,
            fade.isTitleWordFade,
          )}
          <br />
          Guide
        </>
      ),
    }),
    [rotationWords, fade, memoizedRenderTextHighlight],
  );

  return (
    <Container>
      {isMobile ? titleContent.mobile : titleContent.desktop}
    </Container>
  );
}

const renderTextHighlight = (word, isFade) => (
  <TextHighlight $isFade={isFade}>{word}</TextHighlight>
);

export default React.memo(Title);
