import React, { useEffect, useState } from "react";
import { Button, Container, Input } from "./styles";

const Search = ({ onSearch, isResetSearch }) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    if (isResetSearch) {
      setValue("");
    }
  }, [isResetSearch]);

  const handleSearch = () => {
    onSearch(value);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      const val = e.target.value.trim();
      onSearch(val);
      setValue(val);
      try {
        e.target.blur();
      } catch {}
    }
  };

  return (
    <Container>
      <Input
        placeholder="How do you know"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={(e) => setValue(e.target.value.trim())}
        onKeyDown={handleKeyDown}
      />
      <Button type="button" onClick={handleSearch} />
    </Container>
  );
};

export default Search;
