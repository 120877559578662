import React, { useContext, useEffect, useState } from "react";

import HomeLanding from "./HomeLanding";
import { AppContext } from "../contexts/AppContext";
import { validateResetToken, verifyEmail } from "../services/auth";

function Home() {
  let {
    resetToken,
    extensionId,
    confirmationCode,
    isUserNotCreateFirstCampaign,
    isReadyToLaunchCampaign,
    isWarnHighBounceRateCampaign,
    campaignId,
    isQaEnv,
  } = useContext(AppContext);

  const isOpenCampaignsPage = isUserNotCreateFirstCampaign;
  const isOpenCampaignDetailsPage =
    (isReadyToLaunchCampaign || isWarnHighBounceRateCampaign) && campaignId;

  const sendMessageToExtension = (message) => {
    if (!extensionId) {
      setIsExtensionInstalled(false);
      return;
    }

    try {
      // eslint-disable-next-line no-undef
      chrome.runtime.sendMessage(extensionId, message);
    } catch (error) {
      setIsExtensionInstalled(false);
      console.log(`Error: ${error.message}`);
    }
  };

  useEffect(() => {
    if (resetToken && extensionId) {
      const validateToken = async () => {
        const response = await validateResetToken(resetToken, isQaEnv);
        if (!response.success) {
          sendMessageToExtension({
            message: "show-error-message",
            data: {
              message:
                "We’re sorry, but we’re unable to reset your password. Please try again",
            },
          });
          return;
        }
        sendMessageToExtension({
          message: "open-reset-iframe",
          data: {
            invited: response.invited,
            resetToken,
            windowType: "popup",
          },
        });

        const url = new URL(window.location.href);
        url.searchParams.delete("rt");
        window.history.replaceState({}, "", url);
      };

      validateToken();
    }
  }, [resetToken]);

  useEffect(() => {
    if (confirmationCode && extensionId) {
      const verifyUser = async () => {
        const response = await verifyEmail({ confirmationCode }, isQaEnv);
        if (!response.success) {
          sendMessageToExtension({
            message: "show-error-message",
            data: {
              message:
                response.message ||
                "We’re sorry, but we’re unable to confirm your email. Please try again",
            },
          });
          return;
        }
        sendMessageToExtension({
          message: "open-reach-tab",
        });
      };

      verifyUser();
    }
  }, [confirmationCode]);

  function extensionCheckRequired() {
    return isOpenCampaignsPage || isOpenCampaignDetailsPage;
  }

  const isExtensionCheckRequired = extensionCheckRequired();

  const { isExtensionInstalled, setIsExtensionInstalled } =
    useExtensionInstalled(postMessageEventHandler, isExtensionCheckRequired);

  function postMessageEventHandler(event) {
    if (event?.data?.isExtensionInstalled !== undefined) {
      setIsExtensionInstalled(event.data.isExtensionInstalled);
    }
  }

  function useExtensionInstalled(
    postMessageEventHandler,
    isExtensionCheckRequired,
  ) {
    const [isExtensionInstalled, setIsExtensionInstalled] = useState(null);

    useEffect(() => {
      if (isExtensionCheckRequired) {
        window.addEventListener("message", postMessageEventHandler);

        try {
          sendMessageToExtension({
            message: "is-extension-installed",
          });
        } catch (error) {}

        return () => {
          window.removeEventListener("message", postMessageEventHandler);
        };
      }
    }, [postMessageEventHandler, isExtensionCheckRequired]);

    return {
      isExtensionInstalled,
      setIsExtensionInstalled,
    };
  }

  useEffect(() => {
    if (isExtensionInstalled) {
      if (isOpenCampaignsPage) {
        sendMessageToExtension({
          message: "open-campaigns-user-not-create-first-campaign",
        });
      } else if (isOpenCampaignDetailsPage) {
        sendMessageToExtension({
          message: "open-campaign-details-page",
          data: {
            campaignId,
          },
        });
      }

      window.history.replaceState(
        null,
        document.title,
        window.location.pathname,
      );
    }
  }, [isExtensionInstalled]);

  return <HomeLanding isExtensionInstalled={isExtensionInstalled} />;
}

export default Home;
