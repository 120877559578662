import styled from "styled-components";

export const PromoContainer = styled.div`
  width: 100%;
  background: #391952;
  border-radius: 24px;
  margin-top: 51px;

  @media (max-width: 768px) {
    margin-top: 97px;
  }
`;

export const PromoContent = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    position: relative;
  }
`;

export const AnimationContainer = styled.div`
  flex: 1;
  max-width: 580px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  order: 2;
  padding: 51px 49px 0 0;
  align-self: flex-end;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (max-width: 768px) {
    position: absolute;
    top: -54px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 100%;
    padding: 0;
  }
`;

export const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  order: 1;
  flex-grow: 1;
  padding: 30px 115px 51px 91px;
  align-self: flex-end;

  @media (max-width: 768px) {
    padding: 170px 20px 33px 20px;
  }
`;

export const PromoTitle = styled.h2`
  color: #ffffff;
  margin: 10px 0;
  font-family: Inter;
  font-size: 36px;
  font-weight: 800;
  line-height: 34.2px;
  letter-spacing: -0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  span {
    color: #f9ac59;
    font-family: Inter;
    font-size: 36px;
    font-weight: 800;
    line-height: 34.2px;
    letter-spacing: -0.04em;
  }

  @media (max-width: 768px) {
    text-align: center;
    font-size: 27px;
    line-height: 25px;

    span {
      font-size: 27px;
      line-height: 25px;
    }
  }
`;

export const PromoText = styled.p`
  color: #fff;
  margin-top: 10px;
  font-family: Inter;
  font-size: 21px;
  font-weight: 400;
  line-height: 28.35px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding-right: 50px;

  @media (max-width: 768px) {
    text-align: center;
    margin-top: 0;
    padding-right: 0;
    font-size: 14px;
    line-height: 18px;
  }
`;

export const TryButton = styled.a`
  text-decoration: none;
  background: #ffffff;
  border-radius: 9px;
  padding: 15px 95px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #1b0b2b;
  border: none;
  cursor: pointer;
  width: fit-content;
  box-shadow: 0px 4px 4px 0px #00000040;
  margin-left: 35px;
  margin-top: 50px;

  @media (max-width: 768px) {
    margin: 0 auto;
    margin-top: 25px;
    font-size: 14px;
    font-weight: 600;
    padding: 11px 52px;
  }
`;
