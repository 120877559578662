const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const CONTACT_EMAIL = "hello@salesstream.ai";

const INFO_EMAIL = "info@salesstream.ai";

const CONTACT_PHONE_NUMBER = "555-555-5555";

const COMPANY_NAME = "SalesStream.ai";

const ADDRESS = [
  "SalesStreamAI LLC",
  "20200 West Dixie Highway",
  "Suite 902 #1019",
  "Aventura, FL 33180",
];

const CONTACTING_US_TITLE = "Contacting Us";

const PRIVACY_POLICY_CONTENT = [
  {
    title: "Intellectual Property Rights",
    textArray: [
      "Other than the content you own, under these Terms, SalesStream.ai and/or its licensors own all the intellectual property rights and materials contained in this Website",
      "You are granted limited license only for purposes of viewing the material contained on this Website.",
    ],
  },
  {
    title: "Restriction",
    textArray: [
      "Unless specifically authorised, you are specifically restricted from all of the following:",
      "{middot}publishing any Website material in any other media;",
      "{middot}selling, sublicensing and/or otherwise commercializing any Website material;",
      "{middot}publicly performing and/or showing any Website material;",
      "{middot}using this Website in any way that is or may be damaging to this Website / Company Reputation",
      "{middot}using this Website in any way that impacts user access to this Website;",
      "{middot}using this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity;",
      "{middot}engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website;",
      "{middot}using this Website to engage in any advertising or marketing.",
      "Certain areas of this Website are restricted from being access by you, and SalesStream.ai may further restrict access by you to any areas of this Website, at any time, at its absolute discretion. Any user ID and password you may have for this Website are confidential and you must maintain confidentiality as well.",
    ],
  },
  {
    title: "Your Content",
    textArray: [
      "In these Terms of Use, “Your Content” shall mean any audio, video text, images or other material you upload at your free will on this Website. By uploading Your Content, you grant SalesStream.ai a non-exclusive, worldwide, irrevocable, sub-licensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.",
      "Your Content must be your own and must not be invading any third-party's rights. SalesStream.ai reserves the right to remove any of Your Content from this Website at any time without notice. For documentation like Requests for Proposal and Confidential business information, we advise getting in touch with your representatives to maintain confidentiality at all times.",
    ],
  },
  {
    title: "User Data Collection",
    textArray: [
      "Our Chrome Extension ('SalesStream.ai Reach') collects certain user data to provide and improve our services. The types of data we collect include:",
      "{middot} Browser data: Information related to your browser version, type, and settings that help us ensure compatibility and optimal performance.",
      "{middot} Usage data: Details about how you interact with our extension, which can include the features you use and the time spent on our extension.",
    ],
  },
  {
    title: "Data Handling and Storage",
    textArray: [
      "We take the privacy and security of your data seriously. To that end, we implement the following measures:",
      "{middot} Data handling: All user data is handled in strict accordance with this privacy policy and is used solely for the purposes outlined herein.",
      "{middot} Data storage: Collected data is stored on secure servers with restricted access. We retain user data only for as long as necessary to provide our services or as required by law.",
      "{middot} Data encryption: We use industry-standard encryption methods to protect your data from unauthorized access during transmission and storage.",
    ],
  },
  {
    title: "Data Sharing",
    textArray: [
      "We do not sell or rent your personal information to third parties. However, we may share user data in the following circumstances:",
      "{middot} With service providers: We may share your data with third-party service providers who perform services on our behalf, such as hosting, analytics, and customer service. These providers are bound by confidentiality agreements and are permitted to use the data only for the purposes for which they have been engaged.",
      "{middot} For legal reasons: We may disclose your data if required by law or if we believe in good faith that such action is necessary to comply with legal processes, enforce our policies, or protect the rights, property, or safety of our users or the public.",
      "{middot} In case of business transfer: In the event of a merger, acquisition, or asset sale, your data may be transferred. We will provide notice before your data is transferred and becomes subject to a different privacy policy.",
    ],
  },
  {
    title: "Your Rights",
    textArray: [
      "You have certain rights regarding your data, including the right to access, correct, or delete your personal information. If you wish to exercise these rights or have any questions about our data practices, please contact us at info@salesstream.ai",
    ],
  },
  {
    title: "Limitation of liability",
    textArray: [
      "In no event shall SalesStream.ai, nor any of its officers, directors and employees, shall be held liable for anything arising out of or in any way connected with your use of this Website whether such liability is under contract. SalesStream.ai, including its officers, directors and employees shall not be held liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Website.",
    ],
  },
  {
    title: "Indemnification",
    textArray: [
      "You hereby indemnify to the fullest extent SalesStream.ai and its executive officers, partners, employees, and other stakeholders, from and against any and/or all liabilities, costs, demands, causes of action, damages and expenses arising in any way related to your breach of any of the provisions of these Terms",
    ],
  },
  {
    title: "Severability",
    textArray: [
      "If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.",
    ],
  },
  {
    title: "Variation of Terms",
    textArray: [
      "SalesStream.ai reserves the right to revise these Terms of Use at any time as it sees fit, and by using this Website you are expected to review these Terms on a regular basis.",
    ],
  },
  {
    title: "Assignment",
    textArray: [
      "SalesStream.ai is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.",
    ],
  },
  {
    title: "Entire Agreement",
    textArray: [
      "These Terms constitute the entire agreement between SalesStream.ai and you in relation to your use of this Website, and supersede all prior agreements and understandings.",
    ],
  },
  {
    title: "Governing Law & Jurisdiction",
    textArray: [
      "These Terms of Use will be governed by, and interpreted in accordance with, the laws of the Republic of India, and you submit to the exclusive jurisdiction of the relevant courts in the Union Territory of Chandigarh, India for the resolution of any disputes.",
    ],
  },
  {
    title: CONTACTING_US_TITLE,
    textArray: [
      "We are committed to ensuring you have all the information you need to make informed decisions about your privacy, and your use of our products and services, including this website. If you have any questions we haven’t addressed here, please feel free to reach out to us here. Stay Safe.",
    ],
  },
];

const TERMS_CONDITIONS_CONTENT = [
  {
    title: "Intellectual Property Rights",
    textArray: [
      "Other than the content you own, under these Terms, SalesStream.ai and/or its licensors own all the intellectual property rights and materials contained in this Website",
      "You are granted limited license only for purposes of viewing the material contained on this Website.",
    ],
  },
  {
    title: "Restriction",
    textArray: [
      "Unless specifically authorised, you are specifically restricted from all of the following:",
      "{middot}publishing any Website material in any other media;",
      "{middot}selling, sublicensing and/or otherwise commercializing any Website material;",
      "{middot}publicly performing and/or showing any Website material;",
      "{middot}using this Website in any way that is or may be damaging to this Website / Company Reputation;",
      "{middot}using this Website in any way that impacts user access to this Website;",
      "{middot}using this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity;",
      "{middot}engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website;",
      "{middot}using this Website to engage in any advertising or marketing.",
      "Certain areas of this Website are restricted from being access by you, and SalesStream.ai may further restrict access by you to any areas of this Website, at any time, at its absolute discretion. Any user ID and password you may have for this Website are confidential and you must maintain confidentiality as well.",
    ],
  },
  {
    title: "Your Content",
    textArray: [
      "In these Terms of Use, “Your Content” shall mean any audio, video text, images or other material you upload at your free will on this Website. By uploading Your Content, you grant SalesStream.ai a non-exclusive, worldwide, irrevocable, sub-licensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.",
      "Your Content must be your own and must not be invading any third-party's rights. SalesStream.ai reserves the right to remove any of Your Content from this Website at any time without notice. For documentation like Requests for Proposal and Confidential business information, we advise getting in touch with your representatives to maintain confidentiality at all times.",
    ],
  },
  {
    title: "User Data Collection",
    textArray: [
      "Our Chrome Extension ('SalesStream.ai Reach') collects certain user data to provide and improve our services. The types of data we collect include:",
      "{middot} Browser data: Information related to your browser version, type, and settings that help us ensure compatibility and optimal performance.",
      "{middot} Usage data: Details about how you interact with our extension, which can include the features you use and the time spent on our extension.",
    ],
  },
  {
    title: "Data Handling and Storage",
    textArray: [
      "We take the privacy and security of your data seriously. To that end, we implement the following measures:",
      "{middot} Data handling: All user data is handled in strict accordance with this privacy policy and is used solely for the purposes outlined herein.",
      "{middot} Data storage: Collected data is stored on secure servers with restricted access. We retain user data only for as long as necessary to provide our services or as required by law.",
      "{middot} Data encryption: We use industry-standard encryption methods to protect your data from unauthorized access during transmission and storage.",
    ],
  },
  {
    title: "Data Sharing",
    textArray: [
      "We do not sell or rent your personal information to third parties. However, we may share user data in the following circumstances:",
      "{middot} With service providers: We may share your data with third-party service providers who perform services on our behalf, such as hosting, analytics, and customer service. These providers are bound by confidentiality agreements and are permitted to use the data only for the purposes for which they have been engaged.",
      "{middot} For legal reasons: We may disclose your data if required by law or if we believe in good faith that such action is necessary to comply with legal processes, enforce our policies, or protect the rights, property, or safety of our users or the public.",
      "{middot} In case of business transfer: In the event of a merger, acquisition, or asset sale, your data may be transferred. We will provide notice before your data is transferred and becomes subject to a different privacy policy.",
    ],
  },
  {
    title: "Your Rights",
    textArray: [
      "You have certain rights regarding your data, including the right to access, correct, or delete your personal information. If you wish to exercise these rights or have any questions about our data practices, please contact us at info@salesstream.ai",
    ],
  },
  {
    title: "Limitation of liability",
    textArray: [
      "In no event shall SalesStream.ai, nor any of its officers, directors and employees, shall be held liable for anything arising out of or in any way connected with your use of this Website whether such liability is under contract. SalesStream.ai, including its officers, directors and employees shall not be held liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Website.",
    ],
  },
  {
    title: "Indemnification",
    textArray: [
      "You hereby indemnify to the fullest extent SalesStream.ai and its executive officers, partners, employees, and other stakeholders, from and against any and/or all liabilities, costs, demands, causes of action, damages and expenses arising in any way related to your breach of any of the provisions of these Terms",
    ],
  },
  {
    title: "Severability",
    textArray: [
      "If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.",
    ],
  },
  {
    title: "Variation of Terms",
    textArray: [
      "SalesStream.ai reserves the right to revise these Terms of Use at any time as it sees fit, and by using this Website you are expected to review these Terms on a regular basis.",
    ],
  },
  {
    title: "Assignment",
    textArray: [
      "SalesStream.ai is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.",
    ],
  },
  {
    title: "Entire Agreement",
    textArray: [
      "These Terms constitute the entire agreement between SalesStream.ai and you in relation to your use of this Website, and supersede all prior agreements and understandings.",
    ],
  },
  {
    title: "Governing Law & Jurisdiction",
    textArray: [
      "These Terms of Use will be governed by, and interpreted in accordance with, the laws of the Republic of India, and you submit to the exclusive jurisdiction of the relevant courts in the Union Territory of Chandigarh, India for the resolution of any disputes.",
    ],
  },
  {
    title: "Google App Disclosure",
    textArray: [
      "Reach's use and transfer of information received from Google APIs to any other app will adhere to <a href='https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes'>Google API Services User Data Policy</a>, including the Limited Use requirements.",
    ],
  },
  {
    title: CONTACTING_US_TITLE,
    textArray: [
      "We are committed to ensuring you have all the information you need to make informed decisions about your privacy, and your use of our products and services, including this website. If you have any questions we haven’t addressed here, please feel free to reach out to us here. Stay Safe.",
    ],
  },
];

const HUBSPOT_INSTALL_CONTENT = [
  {
    title: "Step 1: Install Chrome Extension",
    textArray: [
      "Begin by installing the Reach Browser Extension.  The Reach Browser Extension will integrate with your " +
        "HubSpot instance and allow you to generate Reach campaigns directly from HubSpot..",
      "To download the extension, visit <a href='https://chromewebstore.google.com/detail/salesstreamai-reach/dpncdmdnpgoigoehmpkpblhdjkcdpaih?authuser=1&hl=en' target='_blank'>https://chromewebstore.google.com/detail/salesstreamai-reach/dpncdmdnpgoigoehmpkpblhdjkcdpaih?authuser=1&hl=en</a> " +
        "or simply click on the 'Install Extension' button at the top of the HubSpot Application page",
      "<img src='images/hubspot/install-guide/step1.png' width='600'>",
    ],
  },
  {
    title: "Step 2: Select HubSpot Account",
    textArray: [
      "You will now be prompted to select the HubSpot account for Reach. Choose the account and click 'Continue with this account'",
      "<img src='images/hubspot/install-guide/step2.png' width='600'>",
    ],
  },
  {
    title: "Step 3: Connect Reach To Your Account",
    textArray: [
      "Now you can connect Reach to your account.  Review the permissions Reach is requesting and click 'Connect app'.  Please remember that Reach will never share your data with anyone",
      "<img src='images/hubspot/install-guide/step3.png' width='600'>",
    ],
  },
  {
    title: "Step 4: Sign Up for Reach",
    textArray: [
      "Now you can connect Reach to your account.  Review the permissions Reach is requesting and click 'Connect app'.  Please remember that Reach will never share your data with anyone",
      "<img src='images/hubspot/install-guide/step3.png' width='600'>",
    ],
  },
  {
    title: "Step 5: Create Integrations",
    textArray: [
      "Now you can specify the integrations you want reach to use.  As you can see, HubSpot is already connected to Reach. Connect your email and SMS provider or skip them for now.",
      "<img src='images/hubspot/install-guide/step5.png' width='600'>",
    ],
  },
  {
    title: "Step 6: Create your first campaign",
    textArray: [
      "You're now ready to create your first campaign.  Give it a title and click 'Create Campaign'",
      "<img src='images/hubspot/install-guide/step6.png' width='600'>",
    ],
  },
  {
    title: "Step 7: Select a campaign goal",
    textArray: [
      "Select a goal for your first campaign.  Use an existing goal, or create one of your own.  Be creative.  There are no limitations!",
      "<img src='images/hubspot/install-guide/step7.png' width='600'>",
    ],
  },
  {
    title: "Step 8: Select a campaign type",
    textArray: [
      "Now select a campaign type or enter one of your own",
      "<img src='images/hubspot/install-guide/step8.png' width='600'>",
    ],
  },
  {
    title: "Step 9: Provide some details",
    textArray: [
      "Finally, provide some details.  Explain in plain text what you're trying to promote, giving as much details as you can.  This information will be used to generate your outreach messages",
      "<img src='images/hubspot/install-guide/step9.png' width='600'>",
    ],
  },
  {
    title: "Step 10: Select a sales strategy",
    textArray: [
      "Reach will now analyze your campaign info and come up with some sales strategies.  These strategies serve as the theme of your email campaign.  Remember, if you'd like, you can always define your own!",
      "<img src='images/hubspot/install-guide/step10.png' width='600'>",
    ],
  },
  {
    title: "Step 11: Select your contacts",
    textArray: [
      "Now select the contacts you'd like to target with this campaign",
      "<img src='images/hubspot/install-guide/step11.png' width='600'>",
      "When you've finished, click 'Return to Reach'",
      "<img src='images/hubspot/install-guide/step11_2.png' width='600'>",
    ],
  },
  {
    title: "Step 12: A few final options and you're done!",
    textArray: [
      "Now tell Reach whether you'd like to use email or SMS, how many times to follow up and which tone to use.  When you're done, click 'Prepare Campaign' to generate personalized messages for every contact you've selected.  You can review them on the following screen",
      "<img src='images/hubspot/install-guide/step12.png' width='600'>",
    ],
  },
  {
    title: "Step 14: Review your messages and launch your campaign",
    textArray: [
      "Reach will now generate your content automatically.  Every contact will receive a completely personalize message based on your campaign parameters.  This process may take a couple of minutes.  ",
      "<img src='images/hubspot/install-guide/step13.png' width='600'>",
      "Once you're ready, you can Review the content or simply Launch your campaign ",
      "<img src='images/hubspot/install-guide/step15.png' width='600'>",
    ],
  },
];

function getBullhornPage1Benefits(isMobile) {
  return [
    {
      icon: "images/save-time.svg",
      iconWidth: isMobile ? "34px" : "54px",
      width: "208px",
      title: "Time Savings",
      content:
        "Automate repetitive outreach tasks to focus on high-value activities.",
    },
    {
      icon: "images/read-message.svg",
      iconWidth: isMobile ? "34px" : "53px",
      width: "208px",
      title: "Increased Engagement",
      content:
        "Use personalized messaging to engage thousands of prospective clients and candidates.",
    },
    {
      icon: "images/speed-icon.png",
      iconWidth: isMobile ? "34px" : "54px",
      width: "208px",
      title: "Improved Efficiency",
      content:
        "Manage client and candidate data and communications in one place.",
    },
    {
      icon: "images/hands-icon.png",
      iconWidth: isMobile ? "39px" : "59px",
      width: "208px",
      title: "Higher Placement Rates",
      content:
        "Quickly identify and reach out to top talents for your clients.",
    },
  ];
}

function getHomePageBenefits(isMobile) {
  return [
    {
      icon: "images/email-icon.png",
      iconWidth: isMobile ? "42px" : "62px",
      width: "228px",
      title: "AI Writing Clone",
      content:
        "Effortlessly craft and send highly-personalized outbound messages to hundreds or thousands of recipients.",
    },
    {
      icon: "images/hands-icon.png",
      iconWidth: isMobile ? "39px" : "59px",
      width: "247px",
      title: "Seamless CRM Integration",
      content:
        "Use your CRM and Reach seamlessly and simultaneously with our Google Chrome Extension.",
    },
    {
      icon: "images/speed-icon.png",
      iconWidth: isMobile ? "34px" : "54px",
      width: "228px",
      title: "Boosted Conversions & Tracking",
      content:
        "Increase conversion rates by delivering the perfect message to every recipient. Track performance in real-time.",
    },
  ];
}

const BENEFITS_DESCRIPTION = {
  part1: `Reach by SalesStream.ai for Bullhorn is a powerful end to end AI-enabled communication platform that puts your business development and recruiting efforts into overdrive.`,
  part2: `Our custom-crafted personalized messages will skip the junk email folder and end up in your clients’ and candidates’ inboxes.`,
};

function getManufacturingBenefits(isMobile) {
  return [
    {
      icon: "images/hubspot/manufacturing/01-production-scheduling.png",
      iconWidth: isMobile ? "34px" : "54px",
      width: "208px",
      title: "Optimize Production Scheduling",
      content:
        "Personalize communication with suppliers and partners to ensure timely deliveries and minimize downtime.",
    },
    {
      icon: "images/hubspot/manufacturing/02-enhance-client-relationship.png",
      iconWidth: isMobile ? "34px" : "53px",
      width: "208px",
      title: "Enhance Client Relationships",
      content:
        "Maintain consistent and personalized communication with clients to build trust and ensure repeat business.",
    },
    {
      icon: "images/hubspot/manufacturing/03-workforce-management.png",
      iconWidth: isMobile ? "34px" : "54px",
      width: "208px",
      title: "Streamline Workforce Management",
      content:
        "Recruit and retain top talent by sending personalized messages that resonate with potential candidates and current employees.",
    },
    {
      icon: "images/hubspot/manufacturing/04-supply-chain-communication.png",
      iconWidth: isMobile ? "39px" : "59px",
      width: "208px",
      title: "Improve Supply Chain Communication",
      content:
        "Efficiently coordinate with suppliers and distributors by automating and personalizing messages based on real-time data.",
    },
  ];
}

const MANUFACTURING_BENEFITS_DESCRIPTION = {
  part1: `Reach by SalesStream.ai is a powerful end to end AI-enabled communication platform that puts your business development and recruiting efforts into overdrive.`,
  part2: `Our custom-crafted personalized messages will skip the junk email folder and end up in your clients’, suppliers’, partners’, distributors’, and candidates’ inboxes.`,
};

function getRetailBenefits(isMobile) {
  return [
    {
      icon: "images/hubspot/retail/01-customer-interaction.png",
      iconWidth: isMobile ? "34px" : "54px",
      width: "208px",
      title: "Personalize Customer Interactions",
      content:
        "Tailor your communication to individual customers, enhancing their shopping experience and fostering loyalty.",
    },
    {
      icon: "images/hubspot/retail/02-marketing-campaign.png",
      iconWidth: isMobile ? "34px" : "53px",
      width: "208px",
      title: "Enhance Marketing Campaigns",
      content:
        "Create and deploy targeted marketing campaigns quickly, without the need for extensive training or complex sequences.",
    },
    {
      icon: "images/hubspot/retail/03-customer-retention.png",
      iconWidth: isMobile ? "34px" : "54px",
      width: "208px",
      title: "Improve Customer Retention",
      content:
        "Send personalized offers and updates to keep your customers engaged and coming back.",
    },
    {
      icon: "images/hubspot/retail/04-streamline-operations.png",
      iconWidth: isMobile ? "39px" : "59px",
      width: "208px",
      title: "Streamline Operations",
      content:
        "Efficiently coordinate with suppliers and manage inventory by automating and personalizing communication based on real-time data.",
    },
  ];
}

const RETAIL_BENEFITS_DESCRIPTION = {
  part1: `Reach by SalesStream.ai is a powerful end to end AI-enabled communication platform that puts your business development and customer engagement efforts into overdrive.`,
  part2: `Our custom-crafted personalized messages will skip the junk email folder and end up in your clients’ inboxes.`,
};

function getFinanceBenefits(isMobile) {
  return [
    {
      icon: "images/hubspot/finance/01-client-onboarding.png",
      iconWidth: isMobile ? "34px" : "54px",
      width: "208px",
      title: "Automate Client Onboarding",
      content:
        "Simplify the client onboarding process by sending automated, personalized welcome emails, information packets, and initial consultation reminders.",
    },
    {
      icon: "images/hubspot/finance/02-financial-reporting.png",
      iconWidth: isMobile ? "34px" : "53px",
      width: "208px",
      title: "Enhance Financial Reporting",
      content:
        "Automatically distribute customized financial reports, summaries, and performance updates to clients, ensuring they are well-informed and engaged.",
    },
    {
      icon: "images/hubspot/finance/03-invoice.png",
      iconWidth: isMobile ? "34px" : "54px",
      width: "208px",
      title: "Efficient Invoice Management",
      content:
        "Automate the sending of invoices, payment reminders, and receipts, reducing manual work and ensuring timely payments.",
    },
    {
      icon: "images/hubspot/finance/04-investment-advice.png",
      iconWidth: isMobile ? "39px" : "59px",
      width: "208px",
      title: "Personalized Investment Advice",
      content:
        "Provide tailored investment recommendations based on individual client profiles, helping clients make informed decisions and build trust.",
    },
  ];
}

const FINANCE_BENEFITS_DESCRIPTION = {
  part1: `Reach by SalesStream.ai is a powerful end to end AI-enabled communication platform that puts your client engagement and business development efforts into overdrive.`,
  part2: `Our custom-crafted personalized messages will skip the junk email folder and end up in your clients’ inboxes.`,
};

function getLegalBenefits(isMobile) {
  return [
    {
      icon: "images/hubspot/legal/01-case-management.png",
      iconWidth: isMobile ? "34px" : "54px",
      width: "208px",
      title: "Efficient Case Management",
      content:
        "Automate the distribution of case notifications, court date reminders, and document submission deadlines, enhancing your firm’s operational efficiency.",
    },
    {
      icon: "images/hubspot/legal/02-legal-updates.png",
      iconWidth: isMobile ? "34px" : "53px",
      width: "208px",
      title: "Individualized Legal Updates",
      content:
        "Send updates on changes in law or policy that specifically impact the client’s area of concern, ensuring they remain well-informed about pertinent legal developments.",
    },
    {
      icon: "images/hubspot/legal/03-client-specific-briefings.png",
      iconWidth: isMobile ? "34px" : "54px",
      width: "208px",
      title: "Client-Specific Briefings",
      content:
        "Send personalized briefings to clients before important meetings or court appearances, detailing what to expect and how to prepare.",
    },
  ];
}

const CHROME_STORE_EXTENSION_URL =
  "https://chromewebstore.google.com/detail/salesstreamai-reach/dpncdmdnpgoigoehmpkpblhdjkcdpaih";

const LEGAL_BENEFITS_DESCRIPTION = {
  part1: `Reach by SalesStream.ai is a powerful end to end AI-enabled communication platform that puts your client communication, case management, and business development efforts into overdrive.`,
  part2: `Our custom-crafted personalized messages will skip the junk email folder and end up in your clients’ inboxes.`,
};

const HOMEPAGE_ANCHOR_CONTAINER_IDs = {
  aboutReach: {
    id: 1,
    key: "aboutReach",
  },
  features: {
    id: 2,
    key: "features",
  },
  testimonials: {
    id: 3,
    key: "testimonials",
  },
  whyReach: {
    id: 4,
    key: "whyReach",
  },
};
const NAVIGATION_TAB_LIST = [
  {
    id: 1,
    key: HOMEPAGE_ANCHOR_CONTAINER_IDs.aboutReach.key,
    name: "About Reach",
    link: "/",
    isAnchor: true,
  },
  {
    id: 2,
    key: HOMEPAGE_ANCHOR_CONTAINER_IDs.features.key,
    name: "Features",
    link: "/",
    isAnchor: true,
  },
  {
    id: 3,
    key: HOMEPAGE_ANCHOR_CONTAINER_IDs.testimonials.key,
    name: "Testimonials",
    link: "/",
    isAnchor: true,
  },
  {
    id: 4,
    key: HOMEPAGE_ANCHOR_CONTAINER_IDs.whyReach.key,
    name: "Why Reach?",
    link: "/",
    isAnchor: true,
  },
  // {
  //   id: 5,
  //   key: "resources",
  //   name: "Resources",
  //   link: "/resources",
  //   isAnchor: false,
  // },
];

const SHOW_NAVBAR_PATHS = ["/", "/resources"];

export {
  EMAIL_REGEX,
  CONTACTING_US_TITLE,
  PRIVACY_POLICY_CONTENT,
  TERMS_CONDITIONS_CONTENT,
  CONTACT_EMAIL,
  INFO_EMAIL,
  CONTACT_PHONE_NUMBER,
  ADDRESS,
  COMPANY_NAME,
  getBullhornPage1Benefits,
  getHomePageBenefits,
  BENEFITS_DESCRIPTION,
  getManufacturingBenefits,
  MANUFACTURING_BENEFITS_DESCRIPTION,
  getRetailBenefits,
  RETAIL_BENEFITS_DESCRIPTION,
  getFinanceBenefits,
  FINANCE_BENEFITS_DESCRIPTION,
  getLegalBenefits,
  LEGAL_BENEFITS_DESCRIPTION,
  HUBSPOT_INSTALL_CONTENT,
  CHROME_STORE_EXTENSION_URL,
  HOMEPAGE_ANCHOR_CONTAINER_IDs,
  NAVIGATION_TAB_LIST,
  SHOW_NAVBAR_PATHS,
};
