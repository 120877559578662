import { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { NAVIGATION_TAB_LIST } from "../utils/constants";

export const NavigationContext = createContext();

export const NavigationProvider = ({ children }) => {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(() => {
    return (
      JSON.parse(localStorage.getItem("selectedTab")) || NAVIGATION_TAB_LIST[0]
    );
  });

  useEffect(() => {
    localStorage.setItem("selectedTab", JSON.stringify(selectedTab));
  }, [selectedTab]);

  useEffect(() => {
    const activeTab = NAVIGATION_TAB_LIST.find(
      (tab) => tab.link === location.pathname,
    );
    if (activeTab) setSelectedTab(activeTab);
  }, [location.pathname]);

  return (
    <NavigationContext.Provider value={{ selectedTab, setSelectedTab }}>
      {children}
    </NavigationContext.Provider>
  );
};
