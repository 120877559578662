import styled from "styled-components";

const BTN_WIDTH = "56px";
const BTN_WIDTH_MOBILE = "28px";

const COL_GAP = "10px";

const INPUT_PADDING = "3px";
const INPUT_PADDING_MOBILE = "7px";

export const Container = styled.div`
  position: relative;
  border-radius: 13.5px;
  height: 63px;
  width: 100%;
  max-width: 642px;
  margin: 68px auto 0;

  @media (max-width: 767px) {
    margin-top: 38px;
    max-width: 325px;
    height: 46px;
  }
`;

export const Input = styled.input`
  padding: ${INPUT_PADDING} calc(${INPUT_PADDING} + ${BTN_WIDTH} + ${COL_GAP})
    ${INPUT_PADDING} 19px;
  width: 100%;
  height: 100%;
  border-radius: 13.5px;
  border: none !important;
  background-color: #f2f2f2 !important;
  color: #000;
  font-family: ${({ theme }) => theme.fonts.poppinsLight};
  font-size: 20px;
  font-weight: 300;
  line-height: 35px;

  @media (max-width: 767px) {
    padding: ${INPUT_PADDING_MOBILE}
      calc(${INPUT_PADDING_MOBILE} + ${BTN_WIDTH_MOBILE} + ${COL_GAP})
      ${INPUT_PADDING_MOBILE} 15px;
    font-size: 16px;
    line-height: 23px;
  }

  outline: none !important;
  box-shadow: 0px 4px 12px 0px #0000000d inset !important;

  &::placeholder {
    color: #161616;
  }

  &:focus {
  }
`;

export const Button = styled.button`
  cursor: pointer;
  width: ${BTN_WIDTH};
  height: 63px;
  border-radius: 0 13.3px 13.3px 0;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.5s ease;

  @media (max-width: 767px) {
    width: ${BTN_WIDTH_MOBILE};
    height: 46px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: url(/images/Search4xb.png);
    background-repeat: no-repeat;
    background-position: center 2px;
    background-size: 32px auto;

    @media (max-width: 767px) {
      background-image: url(/images/Search4xb-xs.png);
      background-position: center 1px;
      background-size: 22px auto;
    }
  }

  &:hover {
    background-color: #d2aaf1;
  }

  &:active {
    background-color: #a476c7;
  }
`;
