import React, { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Scrollbar, Mousewheel } from "swiper/modules";

import { CategoriesContainer, CategoryButton } from "./styles";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";

const Categories = ({ categories, activeCategory, onCategoryChange }) => {
  const swiperRef = useRef(null);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    try {
      const idx = categories.indexOf(activeCategory);
      if (idx !== -1) {
        swiperRef?.current?.swiper?.slideTo(idx);
      }
    } catch {}
  }, [categories, activeCategory]);

  const handleChangeCategory = (cat, idx) => {
    onCategoryChange(cat);
    swiperRef?.current?.swiper?.slideTo(idx);
  };

  return (
    <CategoriesContainer>
      <Swiper
        ref={swiperRef}
        direction={"horizontal"}
        slidesPerView={"auto"}
        freeMode={true}
        scrollbar={true}
        mousewheel={true}
        modules={[FreeMode, Scrollbar, Mousewheel]}
        enabled={!isMobile}
      >
        {categories.map((category, index) => (
          <SwiperSlide key={category}>
            <CategoryButton
              $isActive={activeCategory === category}
              onClick={() => handleChangeCategory(category, index)}
            >
              {category}
            </CategoryButton>
          </SwiperSlide>
        ))}
      </Swiper>
    </CategoriesContainer>
  );
};

export default Categories;
