import styled from "styled-components";

export const Container = styled.p`
  font-family: "Inter";
  font-size: 64px;
  font-weight: 800;
  line-height: 64px;
  color: #fff;
  letter-spacing: -0.02em;
  line-height: 64px;

  @media (max-width: 768px) {
    padding: 0 20px;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: unset;
  }
`;

export const TextHighlight = styled.span`
  font-family: "Inter", sans-serif;
  font-weight: 800;
  transition: opacity 0.5s ease;
  color: #f9ac59;
  opacity: ${({ $isFade }) => ($isFade ? 0 : 1)};
`;
