import { Link } from "react-router-dom";
import styled from "styled-components";

export const Item = styled.li`
  padding: 0 16px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
`;

export const Name = styled.span`
  font-family: "Inter";
  font-size: 16px;
  font-weight: 700;
  line-height: 22.4px;
  color: #000;
  position: relative;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;
