import React, { useRef, useState, useEffect, useCallback } from "react";

import TabItem from "./TabItem";
import { NAVIGATION_TAB_LIST } from "../../../utils/constants";

import { ActiveArrow, List } from "./styles";

function TabsList({ list, selectedTab, handleTabClick }) {
  const tabRefs = useRef(
    Object.fromEntries(NAVIGATION_TAB_LIST.map((tab) => [tab.key, null])),
  );

  const [arrowStyle, setArrowStyle] = useState({ left: 0 });

  const updateArrowPosition = useCallback(() => {
    const selectedElement = tabRefs.current[selectedTab?.key];

    if (selectedElement) {
      const { offsetLeft, offsetWidth } = selectedElement;
      const calculatedLeft = offsetLeft + offsetWidth / 2;

      setArrowStyle((prevStyle) => ({
        left: calculatedLeft,
        opacity: 1,
        transition: prevStyle.left === 0 ? "none" : "all 0.3s ease",
      }));
    }
  }, [selectedTab]);

  useEffect(() => {
    updateArrowPosition();

    window.addEventListener("resize", updateArrowPosition);

    document.fonts.ready.then(updateArrowPosition);

    return () => {
      window.removeEventListener("resize", updateArrowPosition);
    };
  }, [updateArrowPosition]);

  const handleRef = (element, tabKey) => {
    return (tabRefs.current[tabKey] = element);
  };

  return (
    <>
      <List>
        <ActiveArrow style={arrowStyle} />
        {list.map((tab) => (
          <TabItem
            key={tab.id}
            name={tab.name}
            link={tab.link}
            onClick={() => handleTabClick(tab)}
            ref={(element) => handleRef(element, tab.key)}
          />
        ))}
      </List>
    </>
  );
}

export default TabsList;
