import styled from "styled-components";

export const AccordionItem = styled.div`
  background: #ffffff;
  border: 1px solid #000;
  border-radius: 8px;
  overflow: hidden;
`;

export const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  background: #ffffff;
  border-bottom: ${({ $isOpen }) => ($isOpen ? "1px solid #E5E7EB" : "none")};

  &:hover {
    background: #f9fafb;
  }
`;

export const AccordionTitle = styled.h3`
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 35.3px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  color: #111827;
  margin: 0;
  padding-right: 24px;
`;

export const AccordionIcon = styled.span`
  background: ${({ $isOpen }) =>
    $isOpen
      ? "#343434"
      : "linear-gradient(287.64deg, #4eacbb 2.47%, #33d89c 101.41%)"};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transform: rotate(${({ $isOpen }) => ($isOpen ? "180deg" : "0deg")});
  transition: transform 0.2s ease;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`;

export const AccordionContent = styled.div`
  padding: ${({ $isOpen }) => ($isOpen ? "20px" : "0 20px")};
  max-height: ${({ $isOpen }) => ($isOpen ? "1000px" : "0")};
  opacity: ${({ $isOpen }) => ($isOpen ? "1" : "0")};
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  color: #6b7280;
  font-size: 14px;
  line-height: 20px;

  h4 {
    color: #111827;
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 8px 0;
  }

  p {
    margin: 0 0 16px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
