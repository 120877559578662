import styled from "styled-components";

export const CategoriesContainer = styled.div`
  display: flex;
  gap: 0;
  margin-bottom: 81px;
  background: #f3f4f6;
  border-radius: 8px;
  touch-action: pan-y;

  .swiper {
    width: 100%;
    touch-action: pan-y;
  }

  .swiper-slide {
    width: auto;
  }

  @media (max-width: 767px) {
    margin-bottom: 35px;
    padding: 0 14px;
    background: transparent;

    .swiper-wrapper {
      display: grid;
      gap: 8px 15px;
      grid-template-columns: repeat(3, 1fr);
      justify-content: center;
    }
  }

  @media (max-width: 474px) {
    .swiper-wrapper {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

export const CategoryButton = styled.div`
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-size: 15.22px;
  font-weight: 400;
  line-height: 22.82px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 20px 27px;
  width: auto;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  background: ${({ $isActive }) =>
    $isActive
      ? "linear-gradient(263.17deg, #CE8423 3.53%, #FFE600 100%)"
      : "transparent"};
  color: ${({ $isActive }) => ($isActive ? "#111827" : "#6B7280")};
  border-radius: ${({ $isActive }) => ($isActive ? "6px" : "0")};
  box-shadow: ${({ $isActive }) =>
    $isActive ? "0px 1px 3px rgba(0, 0, 0, 0.1)" : "none"};
  outline: none !important;
  position: relative;

  &:hover {
    color: ${({ $isActive }) => ($isActive ? "#111827" : "#374151")};
  }

  @media (max-width: 767px) {
    font-family: ${({ theme, $isActive }) =>
      $isActive ? theme.fonts.poppinsBold : theme.fonts.poppinsPrimary};
    font-weight: ${({ $isActive }) => ($isActive ? "600" : "400")};
    font-size: ${({ $isActive }) => ($isActive ? "12px" : "10px")};
    line-height: 8.81px;
    padding: 6px 5px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px !important;
    color: ${({ $isActive }) => ($isActive ? "#242424" : "#3E444E")};
    ${({ $isActive }) => ($isActive ? "" : "background: #fff;")};
    &:first-of-type {
      grid-area: 1 / 1 / 1 / 4;
      min-width: 130px;
      width: calc((100% - 30px) / 3);
      justify-self: center;
    }
  }

  @media (max-width: 474px) {
    &:first-of-type {
      grid-area: 1 / 1 / 1 / 3;
    }
  }
`;
