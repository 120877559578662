import React, { useState } from "react";
import {
  AccordionItem,
  AccordionHeader,
  AccordionTitle,
  AccordionIcon,
  AccordionContent,
} from "./styles";

const Accordion = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const formatContent = (content) => {
    if (typeof content === "string") {
      return <p>{content}</p>;
    }

    const sections = content.split("\n\n");
    return sections.map((section, index) => {
      if (section.includes(":")) {
        const [title, text] = section.split(":\n");
        return (
          <div key={index}>
            <h4>{title}:</h4>
            <p>{text}</p>
          </div>
        );
      }
      return <p key={index}>{section}</p>;
    });
  };

  return (
    <AccordionItem>
      <AccordionHeader $isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <AccordionTitle>{title}</AccordionTitle>
        <AccordionIcon $isOpen={isOpen}>▼</AccordionIcon>
      </AccordionHeader>
      <AccordionContent $isOpen={isOpen}>
        {formatContent(content)}
      </AccordionContent>
    </AccordionItem>
  );
};

export default Accordion;
