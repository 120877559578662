import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: relative;
  z-index: 1;
`;

export const Description = styled.p`
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-size: 21px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  color: #f8f5f0;
  width: 100%;
  max-width: 678px;
  margin: 24px auto 0;

  @media (max-width: 768px) {
    margin: 12px auto 0;
    padding: 0 40px;
    line-height: 28px;
  }
`;
