import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

import TabsList from "./TabList";
import {
  CHROME_STORE_EXTENSION_URL,
  NAVIGATION_TAB_LIST,
  SHOW_NAVBAR_PATHS,
} from "../../utils/constants";
import { NavigationContext } from "../../contexts/NavigationContext";
import { waitForElement } from "../../utils/utils";

import {
  Container,
  Logo,
  LogoContainer,
  TabsContainer,
  TryLink,
  Wrapper,
} from "./styles";

function MainHeader() {
  const location = useLocation();
  const shouldShowNavbar = SHOW_NAVBAR_PATHS.includes(location.pathname);
  const { selectedTab, setSelectedTab } = useContext(NavigationContext);

  const handleTabClick = async (tab) => {
    if (!tab.isAnchor) return;

    const section = await waitForElement(tab.key);

    if (section) {
      section.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    if (!shouldShowNavbar) return;

    const findActiveTab = () => {
      let newActiveTab = null;

      NAVIGATION_TAB_LIST.forEach((tab) => {
        const section = document.getElementById(tab.key);

        if (section) {
          const rect = section.getBoundingClientRect();

          if (rect.top <= window.innerHeight / 2) {
            newActiveTab = tab;
          }
        }
      });

      if (newActiveTab) {
        setSelectedTab(newActiveTab);
      }
    };

    findActiveTab();
    window.addEventListener("scroll", findActiveTab);
    return () => window.removeEventListener("scroll", findActiveTab);
  }, [shouldShowNavbar, setSelectedTab]);

  return (
    <Wrapper $shouldShowNavbar={shouldShowNavbar}>
      <Container>
        <LogoContainer>
          <Logo
            src="/images/full-logo-pink.png"
            alt="Logo"
            onClick={() => {
              window.location.href = "/";
              setSelectedTab(NAVIGATION_TAB_LIST[0]);
            }}
          />
        </LogoContainer>

        <TabsContainer $shouldShowNavbar={shouldShowNavbar}>
          {shouldShowNavbar && (
            <TabsList
              list={NAVIGATION_TAB_LIST}
              selectedTab={selectedTab}
              handleTabClick={handleTabClick}
            />
          )}

          <TryLink
            $shouldShowNavbar={shouldShowNavbar}
            href={CHROME_STORE_EXTENSION_URL}
            target="_blank"
          >
            Try Reach
          </TryLink>
        </TabsContainer>
      </Container>
    </Wrapper>
  );
}

export default MainHeader;
