import React, { useContext, useEffect, useRef, useState } from "react";
import Benefits from "./Benefits";
import Categories from "./Categories";
import Accordion from "./Accordion";
import PromoSection from "./PromoSection";
import Subscribe from "../../components/Subscribe";
import {
  ResourcesWrapper,
  MainContent,
  CategoryTitle,
  AccordionGrid,
  AccordionColumn,
  BenefitsContainer,
  LoaderContainer,
  Loader,
  EmptyData,
  AccordionsWrapper,
  AccordionGridWrapper,
  PromoSectionWrapper,
} from "./styles";
import { getResources } from "../../services/resources";
import { AppContext } from "../../contexts/AppContext";

const MAX_QUESTIONS_IN_COLUMN = 10;

const Resources = () => {
  const mainContRef = useRef(null);
  const { isQaEnv } = useContext(AppContext);

  const [resources, setResources] = useState([]);
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState("All");
  const [searchByWords, setSearchByWords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchResources = async () => {
    setIsLoading(true);

    const result = await getResources(isQaEnv);

    if (!result.success) {
      setIsLoading(false);
      return;
    }

    setResources(result.megaPages);

    const allCategories =
      result.megaPages.length > 0
        ? ["All", ...new Set(result.megaPages.map((item) => item.topic))]
        : [];

    setCategories(allCategories);

    setIsLoading(false);
  };

  useEffect(() => {
    fetchResources();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleChangeCategory = (cat) => {
    setActiveCategory(cat);
    setSearchByWords([]);
  };

  const handleSearch = (str) => {
    const words = str
      .toLowerCase()
      .split(/\s/g)
      .map((w) => w.replace(/[^A-Za-z0-9.]/g, ""))
      .filter(Boolean);
    setSearchByWords(words);

    setActiveCategory("All");

    try {
      mainContRef.current.scrollIntoView({ behavior: "smooth" });
    } catch {}
  };

  const questions = resources.filter(
    (q) =>
      (activeCategory === "All" ||
        q.topic.toLowerCase() === activeCategory.toLowerCase()) &&
      (!searchByWords.length ||
        searchByWords.some(
          (word) =>
            q.answer.toLowerCase().includes(word) ||
            q.question.toLowerCase().includes(word),
        )),
  );

  const splitQuestionsIntoColumns = (questions) => {
    const chunks = [];
    let leftColumn = [];
    let rightColumn = [];

    // Alternate elements between the left and right columns
    questions.forEach((question, index) => {
      if (
        leftColumn.length < MAX_QUESTIONS_IN_COLUMN &&
        leftColumn.length <= rightColumn.length
      ) {
        leftColumn.push(question);
      } else if (rightColumn.length < MAX_QUESTIONS_IN_COLUMN) {
        rightColumn.push(question);
      }

      // When at least one column is full, we check and add them to chunks.
      if (
        leftColumn.length === MAX_QUESTIONS_IN_COLUMN &&
        rightColumn.length === MAX_QUESTIONS_IN_COLUMN
      ) {
        chunks.push({ leftColumn, rightColumn });
        leftColumn = [];
        rightColumn = [];
      }
    });

    // If there are still elements that did not fill both columns, add them to the last part.
    if (leftColumn.length > 0 || rightColumn.length > 0) {
      chunks.push({ leftColumn, rightColumn });
    }

    return chunks;
  };

  const splittedQuestions = splitQuestionsIntoColumns(questions);

  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader>
          <img src="/images/bouncing-logo.svg" alt="bouncing-logo" />
        </Loader>
      </LoaderContainer>
    );
  }

  return (
    <ResourcesWrapper>
      <BenefitsContainer>
        <Benefits
          onSearch={handleSearch}
          isResetSearch={!searchByWords.length}
        />
      </BenefitsContainer>

      {resources.length === 0 ? (
        <EmptyData>No Resources to show</EmptyData>
      ) : (
        <MainContent ref={mainContRef}>
          <CategoryTitle>Categories</CategoryTitle>
          <Categories
            categories={categories}
            activeCategory={activeCategory}
            onCategoryChange={handleChangeCategory}
          />
          <AccordionsWrapper>
            {splittedQuestions.map((chunk, index) => (
              <AccordionGridWrapper key={index}>
                <AccordionGrid>
                  <AccordionColumn>
                    {chunk.leftColumn.map((question, idx) => (
                      <Accordion
                        key={`left-${idx}`}
                        title={question.question}
                        content={question.answer}
                      />
                    ))}
                  </AccordionColumn>
                  <AccordionColumn>
                    {chunk.rightColumn.map((question, idx) => (
                      <Accordion
                        key={`right-${idx}`}
                        title={question.question}
                        content={question.answer}
                      />
                    ))}
                  </AccordionColumn>
                </AccordionGrid>
                <PromoSectionWrapper>
                  <PromoSection />
                </PromoSectionWrapper>
              </AccordionGridWrapper>
            ))}
          </AccordionsWrapper>
        </MainContent>
      )}
      <Subscribe />
    </ResourcesWrapper>
  );
};

export default Resources;
