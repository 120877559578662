import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  top: ${({ $shouldShowNavbar }) => (!$shouldShowNavbar ? "65px" : "35px")};
  left: 0;
  right: 0;
  padding: 0 10px;
  z-index: 2;

  @media (max-width: 977px) {
    padding-left: 0;
    padding-right: 0;
    top: 0;
  }
`;

export const Container = styled.div`
  background-color: transparent;
  width: 1240px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: center;
  height: 56px;
  position: relative;

  @media (max-width: 1440px) {
    width: 100%;
  }

  @media (max-width: 977px) {
    background-color: #fff;
    height: 63px;
    border: 1px solid #f3f3f3;
  }
`;

export const LogoContainer = styled.div`
  background-color: #fff;
  height: 100%;
  padding: 0 22px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  -webkit-box-shadow: 0px 10px 27px -9px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 10px 27px -9px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 10px 27px -9px rgba(34, 60, 80, 0.2);

  @media (max-width: 977px) {
    padding: 0 16px;
    box-shadow: none;
  }
`;

export const Logo = styled.img`
  width: 105px;
  height: 32px;
  cursor: pointer;
`;

export const TabsContainer = styled.div`
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 5px 0
    ${({ $shouldShowNavbar }) => (!$shouldShowNavbar ? "5px" : "22px")};
  position: relative;
  -webkit-box-shadow: 0px 10px 27px -9px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 10px 27px -9px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 10px 27px -9px rgba(34, 60, 80, 0.2);

  @media (max-width: 977px) {
    box-shadow: none;
  }
`;

export const TryLink = styled.a`
  text-decoration: none;
  display: inline-block;
  background-color: #612686;
  font-family: "WorkSans";
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  border-radius: 10px;
  color: #ffffff;
  padding: 11.8px
    ${({ $shouldShowNavbar }) => (!$shouldShowNavbar ? "21.5px" : "34px")};
  cursor: pointer;

  @media (max-width: 977px) {
    padding-left: 22px;
    padding-right: 22px;
    margin-right: 9px;
  }
`;
