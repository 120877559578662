import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  background: linear-gradient(
    263.83deg,
    #fef8e966 61.82%,
    rgba(246, 247, 252, 0.4) 88.44%
  );
  background-color: #fffcf5;
`;

export const BenefitsContainer = styled.div`
  background-color: #391952;
  position: relative;
  width: 100%;
  height: 690px;
  padding: 178px 15px 0 15px;

  &::before {
    content: "";
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(
      rgba(204, 204, 204, 0.1) 1.5px,
      transparent 0
    );
    background-size: 20px 20px;
  }

  @media (max-width: 977px) {
    padding-top: 105px;
  }
  @media (max-width: 767px) {
    height: 650px;
  }
`;

export const MainContent = styled.div`
  padding: 0 11.3%;

  @media (max-width: 767px) {
    padding: 0 15px;
  }
`;

export const VideoContainer = styled.div`
  margin: -112px auto 0 auto;
`;

export const SquiggleImage = styled.img`
  display: block;
  z-index: 1;
  object-fit: cover;
  position: absolute;
  right: 0;
  bottom: calc(10%);
  max-width: 20vw;
  max-height: 30vh;
  width: auto;
  height: auto;

  @media (max-width: 977px) {
    display: none;
  }
`;

export const RecommendationContainer = styled.div`
  padding-top: 122px;
`;
