import React from "react";
import {
  PromoContainer,
  PromoContent,
  PromoTitle,
  PromoText,
  AnimationContainer,
  TryButton,
  TextContainer,
} from "./styles";
import ThirdAnimation from "../../HomeLanding/Features/Card/Animation/Third";
import { CHROME_STORE_EXTENSION_URL } from "../../../utils/constants";

const PromoSection = () => {
  return (
    <PromoContainer>
      <PromoContent>
        <TextContainer>
          <PromoTitle>
            Create Personalised email campaigns from your{" "}
            <span>spreadsheet for Free</span>
          </PromoTitle>
          <PromoText>
            Tell Reach your campaign goals and Reach will communicate with each
            of your contacts on your behalf
          </PromoText>
          <TryButton href={CHROME_STORE_EXTENSION_URL} target="_blank">
            Try Reach
          </TryButton>
        </TextContainer>
        <AnimationContainer>
          <ThirdAnimation isStarted={true} />
        </AnimationContainer>
      </PromoContent>
    </PromoContainer>
  );
};

export default PromoSection;
