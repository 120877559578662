import React, { forwardRef } from "react";

import { Item, Name, StyledLink } from "./styles";

const TabItem = forwardRef(({ name, link, onClick }, ref) => {
  return (
    <Item ref={ref} onClick={onClick}>
      <StyledLink to={link}>
        <Name>{name}</Name>
      </StyledLink>
    </Item>
  );
});

export default TabItem;
